/* width */
::-webkit-scrollbar {
  width: 7.5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 5px;
}
